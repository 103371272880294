<template>
	<header id="header" class="border-b border-b-mine-shaft flex justify-between">
		<component
			:is="route.path !== '/' ? 'router-link' : 'div'"
			:to="{ name: 'DocumentPackages' }"
		>
			<img src="@/assets/images/anydoc.svg" width="122" class="ml-6 md:ml-10 my-5" />
		</component>

		<div class="flex gap-6">
			<div class="hidden sm:flex sm:flex-col justify-center">
				<div>{{ login }}</div>
				<div v-if="isAdmin" class="text-light-gray text-12">Администратор</div>
			</div>

			<el-dropdown trigger="click" popper-class="header-dropdown" placement="bottom-end">
				<div class="bg-nero cursor-pointer px-4 py-6">
					<ItIcon name="user-alt" class="fill-current text-nero" />
				</div>
				<template #dropdown>
					<el-dropdown-menu class="w-[200px] text-cod-gray">
						<div class="px-3 py-2 text-14 leading-4">
							<div class="mb-1">{{ login }}</div>
							<div v-if="isAdmin" class="text-boulder">Администратор</div>
						</div>

						<template v-if="isAdmin">
							<el-divider class="my-1 border-t-mine-shaft" />

							<el-dropdown-item>
								<div
									class="flex justify-between items-center w-full py-2 px-3"
									@click="router.push({ name: 'Users' })"
								>
									<div>Пользователи</div>
									<ItIcon name="users" width="16" height="16" />
								</div>
							</el-dropdown-item>
							<el-dropdown-item>
								<div
									class="flex justify-between items-center w-full py-2 px-3"
									@click="router.push({ name: 'Settings' })"
								>
									<div>Настройки</div>
									<ItIcon name="settings-alt" width="16" height="16" />
								</div>
							</el-dropdown-item>
						</template>

						<el-divider class="my-1 border-t-mine-shaft" />

						<el-dropdown-item>
							<div
								class="flex justify-between items-center w-full py-2 px-3"
								@click="logout"
							>
								<div>Выйти из аккаунта</div>
								<ItIcon name="arrow-right" width="16" height="16" />
							</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</div>
	</header>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from 'vue'

const router = useRouter()
const route = useRoute()
const store = useStore()

const login = computed(() => store.getters['user/login'])
const isAdmin = computed(() => store.getters['user/isAdmin'])

function logout() {
	store.dispatch('auth/logout')
}
</script>

<style lang="scss">
.header-dropdown {
	.el-dropdown-menu {
		&__item {
			padding: 0;
			line-height: 16px;
		}
	}
}
</style>
